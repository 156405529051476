import React, { useMemo } from 'react';
import { createAvatar } from '@dicebear/core';
import { avataaarsNeutral, botttsNeutral, icons, funEmoji } from '@dicebear/collection';
import { AvatarImage } from './avatar.component.style';

interface AvatarComponentProps {
  aluno: any;
  size?: number;
  hideName?: boolean;
  noLink?: boolean;
  rounded?: boolean;
  countCheckins?: boolean;
}

export const AvatarComponent = ({aluno, size = 200, hideName = false, noLink = false, rounded = false, countCheckins = false}: AvatarComponentProps) => {

  const avatar = useMemo(() => {
    if(aluno?.relationship?.status == 'lead') {
      return createAvatar(funEmoji, {
        size: size,
        seed: aluno?.relationship?.status
      }).toDataUri();
    }
    return createAvatar(avataaarsNeutral, {
      size: size,
      seed: aluno?.getShortName
    }).toDataUri();
  }, []);

  const style = {
    maxWidth: size,
    maxHeight: size,
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0
  }

  if(size) {
    style.overflow = 'hidden';
  }

  if(rounded) {
    style.borderRadius = '50%';
  }

  return (
    <a href={ noLink || !aluno?.id ? undefined : `/${window.account}/admin/alunos/${aluno.id}`} style={style}>
      <div className={`${aluno.gympassEnabled ? 'gympass' : ''} aluno-card`} style={{maxWidth: size, maxHeight: size, margin: 0}}>
        <AvatarImage src={aluno?.avatar?.url || aluno?.avatar?.uri || avatar} alt={`${aluno?.relationship?.status} - ${aluno?.getShortName}`} />
          {!hideName && <div className="aluno-nome" style={{backgroundColor: aluno?.planColor, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}}>{aluno?.getShortName}</div>}
          {!!countCheckins && 
            <div 
              className="aluno-count-checkins" 
              style={{
                borderColor: aluno?.planColor || '#ff000080',
                textOverflow: 'ellipsis', 
                whiteSpace: 'nowrap',
              }}
            >
              {aluno?.checkinsCurrentMonth}
            </div>
          }
      </div>
    </a>  
  )
}