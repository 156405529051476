import React, { useEffect, useState } from 'react';
import { Controller, Form, useForm } from 'react-hook-form';
import { planSchema } from './PlanForm.schema';
import { zodResolver } from '@hookform/resolvers/zod';
import { ProfileCardBodyContainer, ProfileCardPreview, ProfilePlanCardBody, ProfilePlanCardContainer, ProfilePlanCardHeader, ProfilePlanCardStatus, ProfilePlanCardTitle } from './ProfilePlan.style';
import { ButtonStyled, InputStyled, SelectStyled } from '../../../../elements/forms/InputContainer';
import { format } from 'date-fns';
import { PlanFormRow } from './PlanForm.style';
import { useMutation, useQuery } from '@apollo/client';
import { GET_PLANS_QUERY } from '../../querys';
import { CREATE_OR_UPDATE_PLAN_TO_USER } from '../../mutations';
import { useProfile } from '../profile.context';
import { currencyFormat } from '../../../../utils';

const statusOptions = [
  {label: 'Ativo', value: 'active'},
  {label: 'Pendente', value: 'pending'},
  {label: 'Pagamento Pendente', value: 'payment_pending'},
  {label: 'Cancelado', value: 'canceled'},
  {label: 'Fechado', value: 'closed'},
]

const discountTypeOptions = [
  {label: '% Percentual', value: 'percentual'},
  {label: 'Valor Fixo', value: 'fixed'},
  {label: 'Sem Desconto', value: undefined},
]

const paymentMethodOptions = [
  {label: 'Dinheiro', value: 'dinheiro'},
  {label: 'Crédito', value: 'credito'},
  {label: 'Débito', value: 'debito'},
  {label: 'Cheque', value: 'cheque'},
  {label: 'Pix', value: 'pix'},
  {label: 'Transferência', value: 'transferencia'},
  {label: 'Outros', value: 'outros'},
]

interface PlanFormProps { 
  plan: any;
  onClose: () => void;
}

export const PlanForm = ({plan, onClose}: PlanFormProps) => {
  const {aluno} = useProfile();
  const [hasDiscount, setHasDiscount] = useState<'percentual' | 'fixed' | null>(null);
  const [planOptions, setPlanOptions] = useState<any[]>([]);
  const today = new Date();

  const {control, reset, getValues, formState: {errors}, handleSubmit, watch} = useForm({
    resolver: zodResolver(planSchema),
    defaultValues: {
      installments: 1,
      startDate: today.toISOString().split('T')[0],
    }
  });
  const {data: dataPlan, loading: loadingPlan, error: errorPlan} = useQuery(GET_PLANS_QUERY);

  const [submitForm, {data, loading, error}] = useMutation(CREATE_OR_UPDATE_PLAN_TO_USER);
  
  const onSubmit = (data: any) => {
    submitForm({
      variables: {
        id: data.id,
        userId: aluno.id,
        planId: data.planId,
        paymentMethod: data.paymentMethod,
        startDate: data.startDate,
        endDate: data.endDate,
        status: data.status,
        discount: parseFloat(data.discount),
        discountType: data.discountType,
        installments: parseInt(data.installments),
      }
    })
  }

  const planSelected = watch('planId');
  const planSelectedObject = dataPlan?.getPlans.find((plan: any) => plan.id === planSelected);
  const installments = watch('installments') || 1;
  const discount = watch('discount') || 0;
  const discountType = watch('discountType') || null;

  useEffect(() => {
    if(plan) {
      reset(plan);
    }
  }, [plan]);

  useEffect(() => {
    if(data) {
      onClose();
    }
  }, [data]);

  useEffect(() => {
    if(!plan.id && dataPlan) {
      setPlanOptions(dataPlan.getPlans.map((plan: any) => ({label: plan.name, value: plan.id})));
    }
  }, [dataPlan]);

  useEffect(() => {
    if(error){
      const errorMessage = error.graphQLErrors.map((err) => err.message).join(',');
      M.toast({html: errorMessage})
    }
  }, [error])

  const renderSubtotal = () => {
      let subtotal = planSelectedObject.value;
      if(discountType == 'percentual') {
        subtotal = subtotal - (subtotal * (discount/100));
      } else if(discountType == 'fixed') {
        subtotal = subtotal - discount;
      }
      return subtotal;
  }

  return (
    <Form 
      control={control}
      onSubmit={handleSubmit(onSubmit)}
      >
      <ProfilePlanCardContainer>
        <ProfilePlanCardHeader>
          <ProfilePlanCardTitle>{plan.name ? plan.name : 'Adicionar plano ao aluno'}</ProfilePlanCardTitle>
        </ProfilePlanCardHeader>
        <ProfileCardBodyContainer>
          <ProfilePlanCardBody style={{flex: 3}}>

          {!plan.id && <PlanFormRow>
            <div>
              <Controller
                control={control}
                name="planId"
                render={({field}) => 
                  <SelectStyled 
                    {...field} 
                    label="Plano"
                    placeholder="Selecione um plano"
                    options={planOptions}
                    value={planOptions?.find((option: any) => option.value === field.value)}
                    onChange={({value}) => field.onChange(value)}
                    error={errors.planId?.message}
                    className={errors.planId?.message ? 'invalid' : ''}
                />
                }
              />
            </div>
          </PlanFormRow>}
          {!!planSelected && !plan.id && planSelectedObject?.value > 0 && <PlanFormRow>
            <div>
              <Controller
                control={control}
                name="installments"
                render={({field}) => 
                  <InputStyled 
                    {...field} 
                    type="tel"
                    label="Parcelas"
                    error={errors.installments?.message}
                    className={errors.installments?.message ? 'invalid' : ''}
                  />
                }
              />
            </div>
            {installments > 1 && 
              <div>
              <Controller
                control={control}
                name="paymentMethod"
                render={({field, formState}) => 
                  <SelectStyled 
                    {...field} 
                    className="payment-method"
                    type="text"
                    label="Método de Pagamento" 
                    placeholder="Selecione o método de pagamento"
                    error={errors.paymentMethod?.message}
                    className={errors.paymentMethod?.message ? 'invalid' : ''}
                    options={paymentMethodOptions}
                    value={paymentMethodOptions.find(option => option.value === field.value)}
                    onChange={({value}) => field.onChange(value)}
                  />
                }
              />
            </div>
            }
          </PlanFormRow>}
            <Controller
              control={control}
              name="startDate"
              render={({field}) => 
                <InputStyled 
                  {...field} 
                type="date"
                label="Início do plano"
                error={errors.startDate?.message}
                className={errors.startDate?.message ? 'invalid' : ''}
                value={field.value ? format(new Date(field.value), 'yyyy-MM-dd') : ''}
              />
            }
          />
          <Controller
            control={control}
            name="endDate"
            render={({field}) => 
              <InputStyled 
                {...field} 
                type="date"
                label="Término do plano (opcional)"
                error={errors.endDate?.message}
                className={errors.endDate?.message ? 'invalid' : ''}
                value={field.value ? format(new Date(field.value), 'yyyy-MM-dd') : ''}
              />
              }
            />
          <PlanFormRow>
            <div>

            <Controller
              control={control}
              name="status"
              render={({field}) => 
                <SelectStyled 
                  {...field} 
                  type="text"
                  label="Status" 
                  placeholder="Selecione o status"
                  error={errors.status?.message}
                  className={errors.status?.message ? 'invalid' : ''}
                  options={statusOptions}
                  value={statusOptions.find(option => option.value === field.value)}
                  onChange={({value}) => field.onChange(value)}
                  />
            }
            />
            </div>
            <div>
              <Controller
                control={control}
                name="discountType"
                render={({field}) => 
                  <SelectStyled 
                    {...field} 
                    label="Tipo de desconto"
                    error={errors.discountType?.message}
                    className={errors.discountType?.message ? 'invalid' : ''}
                    options={discountTypeOptions}
                    value={discountTypeOptions.find(option => option.value === field.value)}
                    onChange={({value}) => {
                      if(value === null) {
                        setHasDiscount(null);
                      } else {
                        setHasDiscount(value);
                      }
                      field.onChange(value)
                    }}
                  />
                }
              />
            </div>

            {!!hasDiscount && <div>
              <Controller
                control={control}
                name="discount"
                render={({field}) => 
                  <InputStyled 
                    {...field} 
                    label={getValues('discountType') === 'percentual' ? 'Desconto (%)' : 'Desconto (R$)'}
                    error={errors.paymentDate?.message}
                    className={errors.paymentDate?.message ? 'invalid' : ''}
                  />
                }
              />
            </div>}
          </PlanFormRow>

          <PlanFormRow style={{marginTop: '1rem', marginBottom: '1rem', alignItems: 'center'}}>
            <ButtonStyled type="submit">Salvar</ButtonStyled>
            <a href="#" onClick={onClose} style={{color: 'red'}}>Cancelar</a>
          </PlanFormRow>

        </ProfilePlanCardBody>
          {!!planSelected && !!planOptions?.length && 
            <ProfileCardPreview>
              <code><strong>Valor do plano:</strong></code>
              <code>{installments}x {currencyFormat((installments < 2 ? renderSubtotal() : renderSubtotal()/installments)*100)}</code>
              <code>Subtotal: {currencyFormat(renderSubtotal()*100)}</code>
            </ProfileCardPreview>
            }
        </ProfileCardBodyContainer>
      </ProfilePlanCardContainer>
    </Form>
  )
}