import React, { useEffect, useState } from 'react';
import { useProfile } from '../profile.context';
import { ApolloCache, DefaultContext, MutationFunctionOptions, OperationVariables, useMutation, useQuery } from '@apollo/client';
import { GET_ALUNO_PLANS_QUERY } from '../../querys';
import {format} from 'date-fns';

import { 
  PlanCardButton,
  ProfilePlanCardContainer, 
  ProfilePlanCardFooter, 
  ProfilePlanCardHeader, 
  ProfilePlanCardPayment, 
  ProfilePlanCardRow, 
  ProfilePlanCardStatus, 
  ProfilePlanCardTitle 
} from './ProfilePlan.style';
import { PlanForm } from './PlanForm.component';
import { ButtonStyled } from '../../../../elements/forms/InputContainer';
import { LoadingContent, Table, TableBody, TableData, TableHeader, TableHeaderContainer, TableRow } from '../../../../components';
import { currencyFormat } from '../../../../utils';
import { useNavState } from '../../../../components/hookts/useNavState';
import { ActionButton, ActionsContainer } from '../index.style';
import { SET_PAYMENT_RECEIVED_MUTATION } from '../../mutations';

const TRANSLATE_STATUS = {
  active: 'Ativo',
  pending: 'Pendente',
  canceled: 'Cancelado',
  payment_pending: 'Pagamento Pendente',
  closed: 'Fechado',
  finished: 'Finalizado',
  deleted: 'Deletado'
}

interface CardPlanProps {
  plan: any;
  onEdit: (plan: any) => void;
  setPaymentReceived: (options?: MutationFunctionOptions<any, OperationVariables, DefaultContext, ApolloCache<any>> | undefined) => Promise<any> ;
  refetch: (variables?: Partial<T> | undefined) => Promise<any>; 
}

const CardPlan = ({plan, onEdit, setPaymentReceived, refetch}: CardPlanProps) => {
  const {activeOption, aluno, account} = useProfile();
  const renderSubtotal = () => {
    const {discount, discountType, total} = plan;
    let subtotal = total;
    if(discountType == 'percentual') {
      subtotal = subtotal - (subtotal * (discount/100));
    } else if(discountType == 'fixed') {
      subtotal = subtotal - discount;
    }
    return subtotal;
}

const renderDiscount = () => {
  const {discount, discountType} = plan;
  if(discountType == 'percentual') {
    return `${discount}%`;
  } else if(discountType == 'fixed') {
    return currencyFormat(discount * 100);
  }
  return '-';
}

const handleMarkAsReceived = (planPayment: any) => {
  setPaymentReceived({
    variables: {
      id: planPayment.id,
      received: true
    }
  });
}

  return (
    <ProfilePlanCardContainer>
      <ProfilePlanCardHeader>
        <ProfilePlanCardTitle color={plan.planColor}>{plan.name}</ProfilePlanCardTitle>
        <ProfilePlanCardStatus status={plan?.status}>{TRANSLATE_STATUS[plan?.status]}</ProfilePlanCardStatus>
      </ProfilePlanCardHeader>
      <ProfilePlanCardRow><i className="fa-light fa-calendar"></i><strong>Início: </strong>{format(new Date(plan.startDate), 'dd/MM/yyyy')}</ProfilePlanCardRow>
      {!!plan.endDate && <ProfilePlanCardRow><i className="fa-light fa-calendar"></i><strong>Término: </strong>{format(new Date(plan.endDate), 'dd/MM/yyyy')}</ProfilePlanCardRow>}
      {!!plan.planPayments.length &&
        <ProfilePlanCardPayment>
          <Table>
            <TableHeaderContainer>
              <TableHeader>Data</TableHeader>
              <TableHeader>Método de Pagamento</TableHeader>
              <TableHeader>Valor</TableHeader>
              <TableHeader colSpan={2}>Status</TableHeader>
            </TableHeaderContainer>
            <TableBody>
            {plan.planPayments.map((payment: any) => (
              <TableRow key={payment.id}>
                <TableData basis={45} before='Data:'>{format(new Date(payment.date), 'dd/MM/yyyy')}</TableData>
                <TableData basis={45} style={{textTransform: 'capitalize'}}>{payment.paymentMethod}</TableData>
                <TableData basis={45} before='Valor:'>{payment.value > 0 ? currencyFormat(payment.value * 100) : '-'}</TableData>
                <TableData basis={45} before='Status:'>{payment.received ? 'Pago' : 'Pendente'}</TableData>
                <TableData basis={45} className="center" >{payment.received ? <i className="fa-light fa-check"></i> : (
                  <ActionsContainer style={{justifyContent: 'center'}}>
                  <ActionButton onClick={() => handleMarkAsReceived(payment)}>
                    Marcar como recebido
                  </ActionButton>
                      <i className="fa-light fa-triangle-exclamation"></i>
                  </ActionsContainer>
                    )}</TableData>
                </TableRow>
              ))}
            </TableBody>
          </Table>
      </ProfilePlanCardPayment>
    }
      <ProfilePlanCardFooter>
        <ProfilePlanCardRow><strong>Total: </strong>{plan.total > 0 ? currencyFormat(plan.total * 100) : '-'}</ProfilePlanCardRow>
        {plan.discount > 0 && <span><strong>Desconto: </strong>{renderDiscount()}</span>}
        
        {plan.discount > 0 && <span><strong>Subtotal: </strong>{currencyFormat(renderSubtotal() * 100)}</span>}
        {plan.installments > 1 && <span><strong>Parcelas: </strong>{plan.installments}</span>}
        {plan.installments > 1 && <span><strong>Valor da parcela: </strong>{currencyFormat(renderSubtotal() / plan.installments * 100)}</span>}
        <PlanCardButton onClick={() => onEdit(plan)}>
          <i className="fa-light fa-pen"></i>
          Editar
        </PlanCardButton>
      </ProfilePlanCardFooter>
    </ProfilePlanCardContainer>
  )
}

export const ProfilePlan = () => {
  const {activeOption, aluno, account} = useProfile();
  const [isEditingPlan, setIsEditingPlan] = useState<boolean>(false);
  const [selectedPlan, setSelectedPlan] = useState<any>(null);
  const [status, setStatus] = useNavState<'ativados' | 'desativados'>('status', 'ativados');

  const handleEditPlan = (plan: any) => {
    setIsEditingPlan(true);
    setSelectedPlan(plan);
  }

  const handleClosePlanForm = () => {
    setIsEditingPlan(false);
    setSelectedPlan(null);
    refetch();
  }

  const {data, loading, error, refetch} = useQuery(GET_ALUNO_PLANS_QUERY, {
    variables: {
      alunoId: aluno.id,
      status: status
    }
  });

  const [setPaymentReceived, {data: dataPayment, loading: loadingPayment, error: errorPayment}] = useMutation(SET_PAYMENT_RECEIVED_MUTATION);

  useEffect(() => {
    refetch({
      variables: {
        status: status
      }
    });
  }, [status]);

  if (activeOption !== 'planos') return null;

  if (loading) return <LoadingContent loading={loading} />;

  return (
    <div>
      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <div className='flow-text' style={{fontWeight: 600}}>Planos {status == 'ativados' ? 'Ativos' : 'Desativados'}</div>
        <div style={{display: 'flex', gap: 10, cursor: 'pointer'}}>
          {status == 'ativados' && <a onClick={() => setStatus('desativados')}>ver planos desativados</a>}
          {status == 'desativados' && <a onClick={() => setStatus('ativados')}>ver planos ativos</a>}
        </div>
      </div>
      <div>
        {!isEditingPlan && data?.getAlunoPlans?.map((plan: any) => (
          <CardPlan key={plan.id} plan={plan} onEdit={handleEditPlan} setPaymentReceived={setPaymentReceived} refetch={refetch}  />
        ))}
        {isEditingPlan && <PlanForm plan={selectedPlan} onClose={handleClosePlanForm} />}
        {!isEditingPlan && !data?.getAlunoPlans?.length && <div style={{marginBottom: 20}}>Nenhum plano encontrado</div>}
        {!isEditingPlan && <ButtonStyled onClick={handleEditPlan}>Adicionar Plano ao Aluno</ButtonStyled>}
      </div>
    </div>
  )
}